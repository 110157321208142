
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ApiAuth, ApiBase } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "user-settings",
  components: {},
  setup() {
    const i18n = useI18n();
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const saveButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const userInfo = ref(store.getters.currentUser);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: 0,
      status: 0,
      avatar: "media/avatars/blank.png",
      username: "",
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      mobile: "",
      telephone: "",
      address: "",
      zip_code: "",
      postcode: "",
      city: "",
      country_iso_2: "",
      language: "en_US",
      wms_default_warehouse: "",
      timezone: "",
    });

    const countryOptions = ref([]);
    const wareHouseOptions = ref([]);

    const rules = ref({
      username: [
        {
          required: true,
          message: t("user.userName") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: t("user.email") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      firstname: [
        {
          required: true,
          message: t("user.firstName") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      lastname: [
        {
          required: true,
          message: t("user.lastName") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      country_iso_2: [
        {
          required: true,
          message: t("user.country") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      language: [
        {
          required: true,
          message: t("user.language") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      wms_default_warehouse: [
        {
          required: true,
          message: t("user.warehouse") + t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const getUserData = () => {
      loading.value = true;
      ApiAuth.getUserInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = { ...data.data };
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getCountryOptions = () => {
      ApiBase.getCountryData()
        .then(({ data }) => {
          if (data.code == 0) {
            countryOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getWareHouseOptions = async () => {
      const { data } = await ApiBase.getWarehouseData({
        function_type: "1",
      });
      if (data.code == 0) {
        wareHouseOptions.value = data.data;
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          if (saveButton.value) {
            saveButton.value.setAttribute("data-kt-indicator", "on");
          }
          const { data } = await ApiAuth.updateUser(formData.value);
          loading.value = false;
          saveButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              if (String(formData.value.id) === String(userInfo.value?.id)) {
                if (formData.value.language === userInfo.value.language) {
                  setUserInfo(formData.value);
                } else {
                  location.reload();
                }
              } else {
                getUserData();
              }
            });
          } else {
            showServerErrorMsg(data);
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deactivateAccount = () => {
      Swal.fire({
        text: t("common.deactivateAccountTips"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          const { data } = await ApiAuth.deleteUser({
            id: route.params.id,
          });

          loading.value = false;
          deleteButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            Swal.fire({
              text: t("common.accountDeactivatedTips"),
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              router.push({ name: "users" });
            });
          }
        }
      });
    };

    const setLang = (lang) => {
      JwtService.setLang(lang);
      i18n.locale.value = lang;
    };

    const setUserInfo = (value) => {
      store.commit(Mutations.SET_USER, value);
    };

    const removeImage = () => {
      formData.value.avatar = "media/avatars/blank.png";
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getUserData();
      getCountryOptions();
      getWareHouseOptions();
    });

    return {
      t,
      loading,
      formRef,
      rules,
      saveButton,
      deleteButton,
      formData,
      countryOptions,
      wareHouseOptions,
      removeImage,
      submit,
      deactivateAccount,
    };
  },
});
